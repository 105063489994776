<template>
  <v-list-item dense @click="onClick">
    <v-list-item-title>
      {{label| t}}
    </v-list-item-title>
  </v-list-item>
</template>

<script lang="babel" type="text/babel">
import batch from '@/components/list/listToolbar/batch/batch.js'
export default {
  mixins: [batch],
}
</script>

<style lang="sass" type="text/sass" scoped></style>